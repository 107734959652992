import debug from 'debug';

const log = debug('st:darkMode');

(function () {
    const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');

    if (!toggleSwitch) {
        log('No toggleSwitch')
        return;
    }

    log('Found toggleSwitch')

    const currentTheme = localStorage.getItem('theme');

    if (currentTheme) {
        log('currentTheme %O', currentTheme);
        document.documentElement.setAttribute('data-theme', currentTheme);

        if (currentTheme === 'dark') {
            toggleSwitch.checked = true;
        }
    }

    function switchTheme(e) {
        log('switchTheme called with target.checked:%O', e.target.checked);
        if (e.target.checked) {
            document.documentElement.setAttribute('data-theme', 'dark');
            localStorage.setItem('theme', 'dark');
        } else {
            document.documentElement.setAttribute('data-theme', 'light');
            localStorage.setItem('theme', 'light');
        }
    }

    toggleSwitch.addEventListener('change', switchTheme, false);
})();

