import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

FilePond.registerPlugin(FilePondPluginImagePreview);
FilePond.registerPlugin(FilePondPluginImageCrop);
FilePond.registerPlugin(FilePondPluginFileEncode);
FilePond.registerPlugin(FilePondPluginFileValidateType);

document.addEventListener('DOMContentLoaded', () => {
    // Get a reference to the file input element
    const inputElement = document.querySelector('input[type="file"]');

// Create a FilePond instance
    const pond = FilePond.create(inputElement, {
        labelFileProcessingError(error) {
            return error.body || 'خطأ في رفع الملف.'
        },

        acceptedFileTypes: ['image/png', 'image/jpeg'],

        server: {
            url: '/filepond',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                'Accept': 'application/json',
            },
            process: {
                url: '/process',
                onerror(response) {
                    try {
                        const body = JSON.parse(response);

                        if (body.errors && body.errors.filepond) {
                            return body.errors.filepond[0] || response;
                        }

                        return body.message || response;

                    } catch (e) {
                        return response;
                    }
                },
            },

            revert: '/revert',
            // restore: '/restore',
            // load: '/load',
            // fetch: '/fetch',
            // patch: '/patch',
        },
    });
})
