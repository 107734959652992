import './bootstrap';

import './geek/main';

import debug from 'debug';

debug('st:app.js')('Loaded');

// Laracasts Flash Package
$(document).ready(function () {
    $('#flash-overlay-modal').modal();
    $('div.alert').not('.alert-important').delay(3000).fadeOut(350);
});
