import 'owl.carousel';
import debug from 'debug';

const log = debug('st:carousel');

(function () {
    log('Loaded');

    // slider first
    if ($('#owl-testimonial').length) {

        $('#owl-testimonial').owlCarousel({
            rtl: true,
            loop: true,
            margin: 20,
            nav: true,
            dots: false,
            autoplay: true,
            navText: [ '<i class=\'fe fe-chevron-right fs-4 lh-3\'></i>', '<i class=\'fe fe-chevron-left fs-4 lh-3\'></i>' ],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
            },
        });
    }

    if ($('#owl-testimonial-second').length) {

        $('#owl-testimonial-second').owlCarousel({
            rtl: true,
            loop: true,
            margin: 0,
            nav: true,
            dots: false,
            autoplay: true,
            navText: [ '<i class=\'fe fe-chevron-right fs-4 lh-3\'></i>', '<i class=\'fe fe-chevron-left fs-4 lh-3\'></i>' ],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                1000: {
                    items: 1,
                },
            },
        });
    }


    if ($('#owl-slider').length) {

        $('#owl-slider').owlCarousel({
            rtl: true,
            loop: true,
            margin: 20,
            nav: true,
            dots: false,
            autoplay: true,
            navText: [ '<i class=\'fe fe-chevron-right fs-4 lh-3\'></i>', '<i class=\'fe fe-chevron-left fs-4 lh-3\'></i>' ],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                1000: {
                    items: 4,
                },
            },
        });
    }


    if ($('#owl-slider-two').length) {

        $('#owl-slider-two').owlCarousel({
            rtl: true,
            loop: true,
            margin: 20,
            nav: true,
            dots: false,
            autoplay: true,
            navText: [ '<i class=\'fe fe-chevron-right fs-4 lh-3\'></i>', '<i class=\'fe fe-chevron-left fs-4 lh-3\'></i>' ],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                1000: {
                    items: 4,
                },
            },
        });
    }
    if ($('#owl-slider-three').length) {

        $('#owl-slider-three').owlCarousel({
            rtl: true,
            loop: true,
            margin: 20,
            nav: true,
            dots: false,
            autoplay: true,
            navText: [ '<i class=\'fe fe-chevron-right fs-4 lh-3\'></i>', '<i class=\'fe fe-chevron-left fs-4 lh-3\'></i>' ],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                1000: {
                    items: 4,
                },
            },
        });
    }

    if ($('#owl-slider-four').length) {

        $('#owl-slider-four').owlCarousel({
            rtl: true,
            loop: true,
            margin: 20,
            nav: true,
            dots: false,
            autoplay: true,
            navText: [ '<i class=\'fe fe-chevron-right fs-4 lh-3\'></i>', '<i class=\'fe fe-chevron-left fs-4 lh-3\'></i>' ],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                1000: {
                    items: 4,
                },
            },
        });
    }


    if ($('#owl-slider-five').length) {

        $('#owl-slider-five').owlCarousel({
            rtl: true,
            loop: true,
            margin: 20,
            nav: true,
            dots: false,
            autoplay: true,
            navText: [ '<i class=\'fe fe-chevron-right fs-4 lh-3\'></i>', '<i class=\'fe fe-chevron-left fs-4 lh-3\'></i>' ],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                1000: {
                    items: 4,
                },
            },
        });
    }


    if ($('#owl-slider-six').length) {

        $('#owl-slider-six').owlCarousel({
            rtl: true,
            loop: true,
            margin: 20,
            nav: true,
            dots: false,
            autoplay: true,
            navText: [ '<i class=\'fe fe-chevron-right fs-4 lh-3\'></i>', '<i class=\'fe fe-chevron-left fs-4 lh-3\'></i>' ],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                1000: {
                    items: 4,
                },
            },
        });
    }

    if ($('#productSlide').length) {
        $(document).ready(function () {
            var productSlideimage = $('#productSlide');
            var productSlideThumbs = $('#productSlideThumbs');
            //var totalslides = 10;
            var syncedSecondary = true;

            productSlideimage
                .owlCarousel({
                    rtl: true,
                    items: 1,
                    slideSpeed: 2000,
                    nav: false,
                    autoplay: true,
                    dots: false,
                    loop: true,
                    responsiveRefreshRate: 200,
                    navText: [
                        '<i class="fa fa-arrow-left" aria-hidden="true"></i>',
                        '<i class="fa fa-arrow-right" aria-hidden="true"></i>',
                    ],
                })
                .on('changed.owl.carousel', syncPosition);

            productSlideThumbs
                .on('initialized.owl.carousel', function () {
                    productSlideThumbs
                        .find('.owl-item')
                        .eq(0)
                        .addClass('current');
                })
                .owlCarousel({
                    rtl: true,
                    items: 4,
                    dots: false,
                    nav: false,
                    navText: [
                        '<i class="fa fa-arrow-left" aria-hidden="true"></i>',
                        '<i class="fa fa-arrow-right" aria-hidden="true"></i>',
                    ],
                    smartSpeed: 200,
                    slideSpeed: 500,
                    slideBy: 4,
                    responsiveRefreshRate: 100,
                })
                .on('changed.owl.carousel', syncPosition2);

            function syncPosition(el) {
                //if loop is set to false, then you have to uncomment the next line
                //var current = el.item.index;

                //to disable loop, comment this block
                var count = el.item.count - 1;
                var current = Math.round(el.item.index - el.item.count / 2 - 0.5);

                if (current < 0) {
                    current = count;
                }
                if (current > count) {
                    current = 0;
                }
                //to this
                productSlideThumbs
                    .find('.owl-item')
                    .removeClass('current')
                    .eq(current)
                    .addClass('current');
                var onscreen = productSlideThumbs.find('.owl-item.active').length - 1;
                var start = productSlideThumbs
                    .find('.owl-item.active')
                    .first()
                    .index();
                var end = productSlideThumbs
                    .find('.owl-item.active')
                    .last()
                    .index();

                if (current > end) {
                    productSlideThumbs.data('owl.carousel').to(current, 100, true);
                }
                if (current < start) {
                    productSlideThumbs.data('owl.carousel').to(current - onscreen, 100, true);
                }
            }

            function syncPosition2(el) {
                if (syncedSecondary) {
                    var number = el.item.index;
                    productSlideimage.data('owl.carousel').to(number, 100, true);
                }
            }

            productSlideThumbs.on('click', '.owl-item', function (e) {
                e.preventDefault();
                var number = $(this).index();
                productSlideimage.data('owl.carousel').to(number, 300, true);
            });
        });

    }


    if ($('#owl-testimonialThird').length) {

        $('#owl-testimonialThird').owlCarousel({
            rtl: true,
            loop: true,
            margin: 20,
            nav: true,
            dots: false,
            autoplay: true,
            navText: [ '<i class=\'fe fe-chevron-right fs-4 lh-3\'></i>', '<i class=\'fe fe-chevron-left fs-4 lh-3\'></i>' ],
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
            },
        });
    }
})();
