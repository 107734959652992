'use strict';
import debug from 'debug';
import './vendors';
import './darkMode';

const log = debug('st:main');

(function () {
    log('Started...');

    // Multi level menu dropdown
    if ($('.dropdown-menu a.dropdown-toggle').length) {
        log('Found: .dropdown-menu a.dropdown-toggle %O', $('.dropdown-menu a.dropdown-toggle').length);

        $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
            if (!$(this)
                .next()
                .hasClass('show')
            ) {
                $(this)
                    .parents('.dropdown-menu')
                    .first()
                    .find('.show')
                    .removeClass('show');
            }
            var $subMenu = $(this).next('.dropdown-menu');
            $subMenu.toggleClass('show');

            $(this)
                .parents('li.nav-item.dropdown.show')
                .on('hidden.bs.dropdown', function (e) {
                    $('.dropdown-submenu .show').removeClass('show');
                });

            return false;
        });
    }

    // Notification dropdown scroll List
    if ($('.notification-list-scroll').length) {
        log('Found: .notification-list-scroll %O', $('.notification-list-scroll').length);
        $('.notification-list-scroll').slimScroll({
            height: 300,
        });
    }

    // Default Tooltip
    if ($('[data-bs-toggle="tooltip"]').length) {
        log('Found: [data-bs-toggle="tooltip"] %O', $('[data-bs-toggle="tooltip"]').length);
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl);
        });
    }

    // Default Popover
    if ($('[data-bs-toggle="popover"]').length) {
        log('Found: [data-bs-toggle="popover"] %O', $('[data-bs-toggle="popover"]').length);
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl);
        });
    }

    // Radio button - payment method switch
    if ($('#cardRadioone , #cardRadioTwo').length) {
        log('Found: #cardRadioone , #cardRadioTwo %O', $('#cardRadioone , #cardRadioTwo').length);
        $('#internetpayment').hide();
        $('#cardRadioone').on('change', function () {
            if (this.checked) {
                $('#cardpayment').show();
                $('#internetpayment').hide();
            }
        });
        $('#cardRadioTwo').on('change', function () {
            if (this.checked) {
                $('#internetpayment').show();
                $('#cardpayment').hide();
            }
        });

    }

    // Password field strength
    if ($('.password-field input').length) {
        log('Found .password-field input %O', $('.password-field input').length);

        $('.password-field input').on('keyup', function () {
            var strength = checkPasswordStrength($(this).val());
            var $outputTarget = $(this).parent('.password-field');

            $outputTarget.removeClass(function (index, css) {
                return (css.match(/\level\S+/g) || []).join(' ');
            });
            $outputTarget.addClass('level' + strength);
        });

        function checkPasswordStrength(password) {
            var strength = 0;

            // If password is 6 characters or longer
            if (password.length >= 6) {
                strength += 1;
            }

            // If password contains both lower and uppercase characters, increase strength value.
            if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
                strength += 1;
            }

            // If it has numbers and characters, increase strength value.
            if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
                strength += 1;
            }

            return strength;
        }
    }

    // File upload with preview for course cover img
    if ($('[data-upload-id="courseImage"]').length) {
        log('Found: [data-upload-id="courseImage"] %O', $('[data-upload-id="courseImage"]').length);
        const upload = new FileUploadWithPreview.FileUploadWithPreview('courseImage');
    }

    // Menu toggle for admin dashboard
    if ($('#nav-toggle').length) {
        log('Found: #nav-toggle %O', $('#nav-toggle').length);
        // Check if the user hid the nav.
        if (localStorage.getItem('dashboardNav') === 'off') {
            $('#db-wrapper').addClass('toggled');
        }

        $('#nav-toggle').on('click', function (e) {
            e.preventDefault();
            $('#db-wrapper').toggleClass('toggled');

            localStorage.setItem('dashboardNav', $('#db-wrapper').hasClass('toggled') ? 'off' : 'on');
        });
    }

    // Print for invoice
    if ($('#invoice').length) {
        log('Found: #invoice %O', $('#invoice').length);
        $('#invoice').find('.print-link').on('click', function () {
            //Print invoice with default options
            $.print('#invoice');
        });
    }

    // Sidenav fixed for docs
    if ($('.sidebar-nav-fixed a').length) {
        log('Found: .sidebar-nav-fixed a %O', $('.sidebar-nav-fixed a').length);

        $('.sidebar-nav-fixed a')
            // Remove links that don't actually link to anything
            .on('click', function (event) {
                // On-page links
                if (
                    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                    location.hostname == this.hostname
                ) {
                    // Figure out element to scroll to
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top - 90,
                        }, 1000, function () {
                            // Callback after animation
                            // Must change focus!
                            var $target = $(target);
                            $target.focus();
                            if ($target.is(':focus')) { // Checking if the target was focused
                                return false;
                            } else {
                                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                $target.focus(); // Set focus again
                            }

                        });
                    }
                }

                $('.sidebar-nav-fixed a').each(function () {
                    $(this).removeClass('active');
                });
                $(this).addClass('active');
            });
    }

    // Check all for  checkbox
    if ($('#checkAll').length) {
        log('Found: #checkAll %O', $('#checkAll').length);
        $('#checkAll').on('click', function () {
            $('input:checkbox').not(this).prop('checked', this.checked);
        });
    }

    // Btn icon for active
    if ($('#btn-icon').length) {
        log('Found: #btn-icon %O', $('#btn-icon').length);
        $('.btn-icon').on('click', function () {
            $(this).parent().addClass('active').siblings().removeClass('active');
        });
    }

    //  Stopevent for dropdown
    if ($('.stopevent').length) {
        log('Found: .stopevent %O', $('.stopevent').length);
        $(document).on('click.bs.dropdown.data-api', '.stopevent', function (e) {
            e.stopPropagation();
        });
    }

    //  Input tags (Tagify)
    if ($('input[name=tags]').length) {
        log('Found: input[name=tags] %O', $('input[name=tags]').length);
        var input = document.querySelector('input[name=tags]');
        new Tagify(input);

    }

    //  Typed js
    if ($('.headingTyped').length) {
        log('Found: .headingTyped %O', $('.headingTyped').length);
        var typed = new Typed('.headingTyped', {
            strings: [ 'Skills', 'Products ', 'Teams', 'Future' ],
            typeSpeed: 40,
            backSpeed: 40,
            backDelay: 1000,
            loop: true,
        });
    }

    // validation js
    if ($('.needs-validation').length) {
        log('Found: .needs-validation %O', $('.needs-validation').length);
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.querySelectorAll('.needs-validation');

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    form.classList.add('was-validated');
                }, false);
            });

    }

    // toast
    if ($('.toast').length) {
        log('Found: .toast %O', $('.toast').length);
        var toastElList = [].slice.call(document.querySelectorAll('.toast'));
        var toastList = toastElList.map(function (toastEl) {
            return new bootstrap.Toast(toastEl);
        });

    }

    // offcanvas
    if ($('.offcanvas').length) {
        log('Found: .offcanvas %O', $('.offcanvas').length);
        var offcanvasElementList = [].slice.call(document.querySelectorAll('.offcanvas'));
        var offcanvasList = offcanvasElementList.map(function (offcanvasEl) {
            return new bootstrap.Offcanvas(offcanvasEl);
        });

    }

    // dropdown toggle
    if ($('.dropdown-toggle').length) {
        log('Found: .dropdown-toggle %O', $('.dropdown-toggle').length);
        var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'));
        var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
            return new bootstrap.Dropdown(dropdownToggleEl);
        });
    }

    if ($('#dataTableBasic').length) {
        log('Found: #dataTableBasic %O', $('#dataTableBasic').length);
        $(document).ready(function () {
            $('#dataTableBasic').DataTable({

                responsive: true,


            });
        });
    }

    if ($('#liveAlertPlaceholder').length) {
        log('Found: #liveAlertPlaceholder %O', $('#liveAlertPlaceholder').length);
        var alertPlaceholder = document.getElementById('liveAlertPlaceholder');
        var alertTrigger = document.getElementById('liveAlertBtn');

        function alert(message, type) {
            var wrapper = document.createElement('div');
            wrapper.innerHTML = '<div class="alert alert-' + type + ' alert-dismissible" role="alert">' + message + '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';

            alertPlaceholder.append(wrapper);
        }

        if (alertTrigger) {
            alertTrigger.addEventListener('click', function () {
                alert('Nice, you triggered this alert message!', 'success');
            });
        }
    }

    if ($('#typed').length) {
        log('Found: #typed %O', $('#typed').length);
        var typed = new Typed('#typed', {
            stringsElement: '#typed-strings',
            typeSpeed: 40,
            backSpeed: 40,
            backDelay: 1000,
            loop: true,
        });
    }

    if ($('#slider').length) {
        log('Found: #slider %O', $('#slider').length);
        var slider = document.getElementById('slider');
        noUiSlider.create(slider, {

            start: [ 32 ],
            connect: [ true, false ],
            range: {
                'min': 0,
                'max': 60,
            },
            tooltips: [ wNumb({
                decimals: 0,
            }) ],

        });


    }

    if ($('.glightboxGallery').length) {
        log('Found: .glightboxGallery %O', $('.glightboxGallery').length);
        var galleryBox = GLightbox({
            selector: '.glightboxGallery',
        });

    }
})();
