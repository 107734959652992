import * as flatpickr from 'flatpickr';
import * as ar from 'flatpickr/dist/l10n/ar';
import debug from 'debug';

const log = debug('st:flatpickr');

flatpickr.default.localize(ar.default.ar);

const $flatpickr = $('.flatpickr');

if ($flatpickr.length) {
    log('Found .flatpickr %O', $flatpickr.length);

    flatpickr.default('.flatpickr', {
        locale: {
            firstDayOfWeek: 0
        },
        disableMobile: true,
        // enableTime: true,
    });
}
